// Font Family
@import url("https://fonts.googleapis.com/css2?family=Cormorant:wght@500;700&family=IBM+Plex+Sans:wght@400;500;700&display=swap");
//Color variables
$getzenprimary: #11303d;
$getzenprimary-hover: #0e2631;
$getzenprimary-visited: #11303d;
$getzensecondary: #b68e63;
$getzensecondary-hover: #c5a582;
$getzensecondary-visited: #b68e63;
$getzenoutline: #ffffff;
$getzenoutline-hover: #edecea;
$getzenoutline-visited: #edecea;
$getzenoutline-border: #cdc7bf;
$getzencontainer-gray-color: #dee2e6;
//Btn styles
$btn-border-radius: 0.313rem;
$btn-border-radius-sm: 0.313rem;
$btn-border-radius-lg: 0.313rem;
$btn-font-weight: 700;
$font-size-base: 1.125rem;
$h1-font-size: 3.375rem;
$h2-font-size: 2.25rem;
$h3-font-size: 1.875rem;
$h4-font-size: 1rem;
//import the functions & Variables
@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";
$getzen-theme-colors: (
  "getzenprimary": $getzenprimary,
  "getzensecondary": $getzensecondary,
);
$theme-colors: map-merge($getzen-theme-colors, $theme-colors);
//Import Boostrap
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

html {
  body {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
}

.btn {
  min-width: 50px;

  &.action-btn-md {
    min-width: auto;
  }
}

.fa-1x {
  font-size: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cormorant", serif;
  font-weight: 700 !important;
  margin-bottom: 0.5rem !important;
}

.btn-primary {
  color: white;

  &:hover {
    background-color: $getzenprimary-hover;
  }

  &:visited {
    background-color: $getzenprimary-visited;
  }

  &:active {
    background-color: $getzenprimary;
  }
}

.btn-secondary {
  color: black;

  &:hover {
    background-color: $getzensecondary-hover;
  }

  &:visited {
    background-color: $getzensecondary-visited;
  }

  &:active {
    background-color: $getzensecondary;
  }
}

form {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  label {
    font-size: 14px;
  }
}

.button-login {
  button {
    width: 120px;
  }
}

.logout-btn {
  font-family: "IBM Plex Sans", sans-serif;
}

.dp-down-n {
  position: absolute;
  top: 85px !important;
  right: 27px !important;
}

.dp-down {
  position: absolute;
  top: 85px;
  right: 60px;
  background-color: #fff;
  width: 100%;
  max-width: 320px;
}

.forgot-password {
  font-size: 14px;
}

.btn-outline {
  color: black;
  border: 1px solid $getzenoutline-border;

  &:hover {
    background-color: $getzenoutline-hover;
  }

  &:visited {
    background-color: $getzenoutline-visited;
  }

  &:active {
    background-color: $getzenoutline;
  }
}

.profile {
  height: 50px;
  width: 50px;
}

.name-topbar {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #11303d;
}

.profile-border {
  border: 2px solid #b68e63 !important;
  width: 70.5px;
  height: 70.5px;
}

.h5 {
  &.practitioner-model {
    margin-bottom: 0px !important;
    color: #000000;
  }
}

.card-box {
  position: absolute;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 24px;
  width: 391px;
  height: 474px;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rmsc {
  .dropdown-container {
    .dropdown-heading {
      height: 33px;
      padding: 0 0.5rem;

      .gray {
        stroke: #595e63;
      }
    }

    .dropdown-content {
      z-index: 9999;
    }
  }
}

.spn-c-p {
  cursor: pointer;
}

.form-control[readonly] {
  &.pop-input {
    background-color: #ffffff;
  }
}

.ant-picker {
  &.form-control-sm {
    border-radius: 0.2rem;
    border: 1px solid #ced4da;
    padding-left: 0.5rem;
    font-size: 0.98438rem;

    &.ant-picker-disabled {
      background-color: #e9ecef;
    }
  }
}

.fc {
  &.fc-media-screen {
    .fc-today-button {
      text-transform: lowercase;
    }

    .fc-toolbar-title {
      font-family: "Cormorant", serif;
      font-weight: 400;
    }

    .fc-h-event {
      background-color: #11303d;
    }

    .fc-button-group {
      .fc-button {
        display: flex;
      }
    }

    .fc-button-primary {
      color: #fff;
      background-color: #0e2631;
      border-color: #0e2631;
    }

    .fc-col-header-cell-cushion {
      color: #000;
    }

    .fc-daygrid-day-number {
      color: #000;
    }

    .fc-daygrid-day {
      &.fc-day-past {
        .fc-daygrid-day-top {
          opacity: 0.3;
        }
      }
    }
  }
}

.fc-direction-ltr {
  .fc-daygrid-event {
    .fc-event-time {
      display: none;
    }

    .fc-daygrid-event-dot {
      display: none;
    }

    .fc-event-title {
      margin-left: 8px;
      color: #fff;
      line-height: 1.4;
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: 400;
    }

    &.fc-event-end {
      padding: 4px 0px;
      margin-top: -5px;

      &:hover {
        background-color: #000;
      }
    }
  }
}

.fc-event {
  background-color: #000;
  position: relative;
  display: block;
  font-size: 0.85em;
  line-height: 1.4;
  border-radius: 3px;
  border: 1px solid #0e2631;
}

//Calender controller
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #e7e7e7 !important;
}

.fw-medium-bold {
  font-weight: 600 !important;
}

.fa-1sm {
  font-size: 18px;
}

.box-border {
  border: 1px solid #f3f3f3;
}

.t-u {
  text-decoration: underline;
  text-underline-position: under;
}

.hoMdai {
  max-height: 100% !important;
}

.f-16 {
  font-size: 16px;
}

.select-search {
  .select-search__value {
    input {
      min-height: calc(1.5em + 0.5rem + 2px);
      padding: 0.25rem 0.5rem;
      font-size: 14px;
      border-radius: 0.2rem;
      display: block;
      width: 100%;
      font-weight: 400;
      line-height: 1.5;
      color: hwb(0deg 0% 100% / 85%);
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background-image: escape-svg($form-select-indicator);
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 16px 12px;

      &::placeholder {
        color: #aaa;
      }

      &:-ms-input-placeholder {
        color: #aaa;
      }

      &::-ms-input-placeholder {
        color: #aaa;
      }
    }
  }

  .select-search__select {
    border: 1px solid #000;
    background-color: #fff;

    .select-search__options {
      list-style: none;
      padding: 0px;
      margin: 0px;

      .select-search__row {
        .select-search__option {
          text-align: left;
          width: 100%;
          border: none;
          background-color: #fff;
        }
      }
    }
  }
}

.error {
  color: rgb(241, 85, 85);
}

.info-alert_box {
  position: fixed;
  top: 100px;
  right: 50%;
  transform: translateX(50%);
  z-index: 9999;
}

.globalLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000080;
  z-index: 9999;

  .g-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .loading-icon {
      width: 50px;
      height: 50px;
    }
  }
}

.css-b62m3t-container {
  .css-1s2u09g-control {
    max-height: 33.63px;
    min-height: unset;
    align-content: center;
    padding: 0px 8px;
    .css-319lph-ValueContainer {
      padding: 0px 0px;
      .css-qc6sy-singleValue {
        font-size: 0.98438rem;
        color: #000;
      }
    }
  }

  .css-26l3qy-menu {
    z-index: 9999;
  }
}

.sortby {
  height: 48px;
  width: 48px;
  display: inline-block;
  background-color: #11303d;
  background-image: url(../../public/assets/img/Sort-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.filter {
  height: 48px;
  width: 48px;
  display: inline-block;
  background-color: #11303d;
  background-image: url(../../public/assets/img/Filter.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.bg-secondary {
  background-color: #b68e63 !important;
}

small {
  font-size: 0.875em !important;
}

.card {
  .card-body {
    height: 260px;
  }
}

//Sider Pannel
.sidebar {
  height: 100%;
  width: 350px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: -350px;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #11303d;
    display: block;
    transition: 0.3s;
    &:hover {
      color: #11303d;
    }
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
}

.proDetails {
  width: 500px;
  height: 500px;
  background: #cdc7bf;
  border-radius: 360px;
  position: relative;
  .img {
    width: 447px;
    border-radius: 5px;
    position: absolute;
    left: 3%;
    bottom: 0%;
  }
}

.btn:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.text-secondary {
  color: #b68e63 !important;
}

.navbar-expand-lg {
  .navbar-nav {
    margin-left: auto;
    margin-right: auto;

    .nav-item {
      .nav-link {
        color: #000;
      }
    }
    .nav-link {
      color: #000;
      position: relative;
      display: inline-block;
      &.active {
        &::after {
          content: "";
          width: 100%;
          height: 4px;
          background-color: #b68e63;
          display: block;
          position: absolute;
          left: 0;
          bottom: -5px;
        }
      }
    }
  }
}

.practitioners {
  &.not-active {
    background-color: #f0f0f0;
    cursor: not-allowed !important;
  }
  &:hover {
    background-color: #edecea;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

.col-bl-5 {
  flex: 0 0 auto;
  width: 18.4333333333%;
}

.tb-design-1 {
  .hoMdai {
    .ifOHjV {
      .rdt_Table {
        .rdt_TableHead {
          .rdt_TableHeadRow {
            border: none;

            .rdt_TableCol {
              border: none;
              border-bottom: 2px solid #212529;

              .rdt_TableCol_Sortable {
                font-weight: bold;
                color: #212529;
                font-size: 16px;
              }
            }
          }
        }

        .rdt_TableBody {
          .rdt_TableRow {
            background-color: transparent;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: rgba(0, 0, 0, 0.12);

            &:hover {
              background-color: rgba(0, 0, 0, 0.075);
            }

            .rdt_TableCell {
              border: none;

              &:first-child {
                font-weight: bold;
                color: #212529;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.activeTimeSlotbtn {
  background-color: #000000;
  color: #ffffff;
  box-shadow: 0 0 0 0.25rem #88979e;
}

.star-5 {
  font-size: 40px;
}

.rating-heading {
  font-weight: 500;
  font-size: 21px;
}

.rating-font {
  font-size: 21px;
  text-align: left;
  font-family: "Cormorant", serif;
}

.rm-scroll {
  overflow: hidden; /* Hide scrollbars */
}

.time-slot-btn {
  &:disabled {
    cursor: not-allowed;
    pointer-events: unset;

    &:active {
      background-color: transparent;
      color: #11303d;
    }
  }
}

.table-height-rtd {
  max-height: unset !important;
}

.form-control {
  &:focus {
    border-color: unset;
    box-shadow: none;
  }
}

.search-box {
  position: relative;
  width: calc(100% - 70px);
  .searchinput-clear-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.programme-search-filter-container {
  background-color: $getzencontainer-gray-color;
}

.search-input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.btn {
  &.programme-search-btn-zindex {
    z-index: 1;
  }
}

.rating-box{
  width: 450px;
  height: auto;
}
@media only screen and (max-width: 992px) {
  .user-icon-panel {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 10px;
  }

  .dp-down-n {
    top: 262px !important;
    left: 0px !important;
    margin-left: 0.5rem;
    right: unset !important;
  }
}
